<!-- 登录页 -->
<template>
    <div style="background: #fff;height:100%;">
        <van-nav-bar title="登录"/>
        <div style="margin-top:100px">
           <img src="../assets/imgs/logo1.jpg" alt="" style="width:100%">
        </div>
        <div style="width:80%;margin:50px auto">
            <van-form @submit="onSubmit">
                <van-field
                    v-model="username"
                    left-icon="column"
                    placeholder="请输入卡号"
                    style="border:1px solid #f2f3f4"
                />
                <div class="password-box">
                    <div class="grid-item">
                        <van-field
                            v-model="password"
                            :type="switchChecked?'text':'password'"
                            left-icon="lock"
                            placeholder="请输入密码"
                            :rules="[{ required: true}]"
                        />
                    </div>
                    <van-switch v-model="switchChecked" size="20" />
                </div>
            <div style="margin: 16px;">
                <van-button round block type="info" native-type="submit">登录</van-button>
            </div>
        </van-form>
          
        </div>
          
    </div>
</template>
<script>
import {login} from '@/api/base'
import { Notify } from 'vant';
export default {
  name: 'logon',
  data(){
    return {
        username: '',
        password: '',
        switchChecked: false,
        sliderValue:0,
        showPassword:false,
        isreadonly:false
    }
  },
  mounted(){
    this.username=sessionStorage.getItem("username");
    if(this.username){
        this.isreadonly=true;
    }
  },
  methods:{
    onSubmit(values) {
       let params={
        username: this.username, 
        password: this.password, 
       }
       login(params).then((res)=>{
            if(res.code==0){
                this.memeberInfo=res.data;
                sessionStorage.setItem("Authorization", res.data.accessToken);
                sessionStorage.setItem("username", this.username);
                this.$router.push({ path: `/mine/card/${this.username}`});
                if(res.data.msg!==null){
                  Notify({ type: 'success', message: res.data.msg });
                }
            }else{
              Notify({ type: 'warning', message: res.msg });
            }
       },(err)=>{
        Notify({ type: 'warning', message: '网络错误' });
       })
      
    },
  }
}
</script>
<style lang="scss"  scoped>
   .van-button{
    background:#ac931b;
    border-color:#ac931b;
   }
   html, body {
    background: #fff !important;
   }
   /deep/ .van-field__left-icon .van-icon, .van-field__right-icon .van-icon {
    color: #ccc !important; 
  }
  .password-box{
    display: flex;
    border:1px solid #f2f3f4;
    border-top:none;
    .grid-item:nth-child(1){
     flex: 1;
    }
    .van-switch{
        position: relative;
        top:10px;
        right:10px;
    }
  }
</style>
